import React from 'react';
import { Helmet } from 'react-helmet';
import { FaHome, FaInfoCircle, FaEnvelope, FaSignInAlt, FaComments, FaShieldAlt, FaChartBar, FaHandshake, FaLock, FaUsers } from 'react-icons/fa'; // Библиотека Font Awesome
import './home.css';

const Home = (props) => {
  return (
    <div className="home-container1">
      <Helmet>
        <title>Sphera Exchange</title>
      </Helmet>
      <div className="home-home">
        <div className="home-navbar5">
          <div className="home-content10">
            <div className="home-content11">
              <div className="home-color-dark1">
                <img
                  src="/external/sphera.png"
                  alt="Логотип"
                  className="home-logowide11"
                />
              </div>
              <div className="home-column1">
                <span className="home-text10 TextRegularNormal">
                  Главная
                </span>
                <span className="home-text11 TextRegularNormal">
                  Услуги
                </span>
                <span className="home-text12 TextRegularNormal">
                  О нас
                </span>
                <div className="home-nav-link-dropdown">
                  <span className="home-text13 TextRegularNormal">
                  Свяжитесь с нами
                  </span>
                </div>
              </div>
            </div>
            <div className="home-actions1">
              <div className="home-style-primary-small-true-alternate-false-iconposition-noi">
                <span className="home-text15" >
                  <a href='https://t.me/sphera_exchange'><FaComments /> Чат</a>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="home-header15">
          <div className="home-content12">
            <div className="home-column2">
              <span className="home-text16 HeadingDesktopH1">
                Sphera Exchange: Торгуйте криптовалютой с уверенностью
              </span>
            </div>
            <div className="home-column3">
              <span className="home-text17 TextMediumNormal">
                Добро пожаловать на Sphera Exchange, где ваш опыт торговли криптовалютой — наш главный приоритет. Наслаждайтесь удобной платформой, разработанной как для новичков, так и для опытных трейдеров.
              </span>
              <div className="home-actions2">
                <div className="home-style-primary-small-false-alternate-false-iconposition-no1">
                  <span className="home-text18"><a href='https://t.me/sphera_exchange'><FaComments /> Чат</a></span>
                </div>
                <div className="home-style-secondary-small-false-alternate-false-iconposition1">
                  <span className="home-text19">Узнать больше</span>
                </div>
              </div>
            </div>
          </div>
          {/* <img
            src="/external/placeholderimage6128-pyu-800h.png"
            alt="Заглушка"
            className="home-placeholder-image1"
          /> */}
        </div>
        <div className="home-layout242">
          <span className="home-text20 HeadingDesktopH3">
            Начните свое путешествие в мир криптовалют с Sphera Exchange уже сегодня!
          </span>
          <div className="home-content13">
            <div className="home-row1">
              <div className="home-list-item1">
                <div className="home-content14">
                  <FaChartBar className="home-icon-relume1" />
                  <span className="home-text21 HeadingDesktopH5">
                    Простые шаги для начала торговли
                  </span>
                  <span className="home-text22 TextRegularNormal">
                    Создайте аккаунт, пополните баланс и начните торговать мгновенно.
                  </span>
                </div>
                <div className="home-action1">
                  <div className="home-style-link-small-false-alternate-false-iconposition-trail1">
                    <span className="home-text23">Присоединиться</span>
                  </div>
                </div>
              </div>
              <div className="home-list-item2">
                <div className="home-content15">
                  <FaShieldAlt className="home-icon-relume2" />
                  <span className="home-text24 HeadingDesktopH5">
                    Защитите свои инвестиции с помощью передовых инструментов
                  </span>
                  <span className="home-text25 TextRegularNormal">
                    Используйте наш удобный интерфейс для эффективного управления сделками.
                  </span>
                </div>
                <div className="home-action2">
                  <div className="home-style-link-small-false-alternate-false-iconposition-trail2">
                    <span className="home-text26">Торговать</span>
                  </div>
                </div>
              </div>
              <div className="home-list-item3">
                <div className="home-content16">
                  <FaLock className="home-icon-relume3" />
                  <span className="home-text27 HeadingDesktopH5">
                    Доступ к данным рынка в реальном времени
                  </span>
                  <span className="home-text28 TextRegularNormal">
                    Оставайтесь в курсе трендов и увеличивайте прибыль.
                  </span>
                </div>
                <div className="home-action3">
                  <div className="home-style-link-small-false-alternate-false-iconposition-trail3">
                    <span className="home-text29">Анализировать</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="home-layout12">
          <div className="home-container2">
            <div className="home-content17">
              <div className="home-section-title1">
                <span className="home-text30 HeadingDesktopH3">
                  Испытайте непревзойденную безопасность с Sphera Exchange для ваших криптовалютных транзакций
                </span>
                <span className="home-text31 TextMediumNormal">
                  В Sphera Exchange ваша безопасность — наш главный приоритет. Мы используем передовое шифрование и многофакторную аутентификацию для защиты ваших активов.
                </span>
              </div>
              <div className="home-content18">
                <div className="home-row2">
                  <div className="home-list-item4">
                    <FaShieldAlt className="home-icon-relume4" />
                    <span className="home-text32 HeadingDesktopH6">
                      Надежная защита
                    </span>
                    <span className="home-text33 TextRegularNormal">
                      Наша платформа оснащена мониторингом в реальном времени для предотвращения несанкционированного доступа.
                    </span>
                  </div>
                  <div className="home-list-item5">
                    <FaUsers className="home-icon-relume5" />
                    <span className="home-text34 HeadingDesktopH6">
                      Доверие пользователей
                    </span>
                    <span className="home-text35 TextRegularNormal">
                      Присоединяйтесь к сообществу, которое ценит прозрачность и безопасность в каждой транзакции.
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <img
              src="/external/logo_art.jpg"
              alt="Placeholder"
              className="home-placeholder-image2"
            />
          </div>
        </div>
        <div className="home-footer4">
          <div className="home-content36">
            <div className="home-logo">
              <div className="home-color-dark2">
                <img
                  src="/external/sphera.png"
                  alt="Логотип"
                  className="home-logowide12"
                />
              </div>
            </div>
            <div className="home-links">
              <span className="home-text67 TextSmallSemiBold">
                О нас
              </span>
              <span className="home-text68 TextSmallSemiBold">
                Начать
              </span>
              <span className="home-text69 TextSmallSemiBold">
                Центр поддержки
              </span>
              <span className="home-text70 TextSmallSemiBold">
                Свяжитесь с нами
              </span>
              <span className="home-text71 TextSmallSemiBold">
                Раздел FAQ
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home
